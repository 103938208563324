import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import MembershipPage from "../pagesLayouts/MembershipPage"
import { WordpressPageQuery } from "../types/schema"

export default function MembershipPl() {
  const query = graphql`
    query {
      wpPage(language: { slug: { eq: "pl" } }, slug: { eq: "membership" }) {
        ...WordpressPageFragment
      }
    }
  `
  const data = useStaticQuery<WordpressPageQuery>(query)

  return <MembershipPage data={data} />
}
